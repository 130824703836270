import React from 'react';
import {
  Tooltip,
  Typography,
  Chip,
  Box,
  IconButton,
  Switch,
  CircularProgress,
} from '@mui/material';
import PropTypes from 'prop-types';
import CELL_DATA_TYPES from '../../config/cell-types.config';
import {
  formatNumber,
  formatPercent,
  formatDuration,
  formatCurrency,
} from '../../utils/numbers.util';
import { formatDate, formatTimestamp } from '../../utils/datetime.util';
import StatusLabel from './status-label.component';
import PlatformLabel from './platform-label.component';
import Iconify from '../common/iconify.component';
import { APP_THEME } from '../../theme/theme-colors';

const CellValue = ({ type, value, fallbackValue, cdnStatus }) => {
  if (type === CELL_DATA_TYPES.NUMBER) {
    return (
      <Typography variant="body2" component="span">
        {formatNumber(value || 0)}
      </Typography>
    );
  }
  if (type === CELL_DATA_TYPES.PERCENTAGE) {
    return (
      <Tooltip title={value || 0} arrow>
        <Typography variant="body2" component="span">
          {formatPercent(value || 0)}
        </Typography>
      </Tooltip>
    );
  }
  if (type === CELL_DATA_TYPES.PROGRESS) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <CircularProgress
          determinate
          size={45}
          thickness={10}
          variant="determinate"
          value={value || 0}
          color="primary"
        />
        <Typography
          position="absolute"
          sx={{ fontWeight: 'bold', mx: 0.5, fontSize: 12 }}
        >
          {value || 0}%
        </Typography>
      </Box>
    );
  }
  if (type === CELL_DATA_TYPES.MONEY) {
    return (
      <Tooltip title={value || '0'} arrow>
        <Typography variant="body2" component="span">
          {formatCurrency(value || 0)}
        </Typography>
      </Tooltip>
    );
  }
  if (type === CELL_DATA_TYPES.DURATION) {
    return (
      <Tooltip title={`${value || 0} seconds`} arrow>
        <Typography variant="body2" component="span">
          {formatDuration(value || 0)}
        </Typography>
      </Tooltip>
    );
  }
  if (type === CELL_DATA_TYPES.DATE) {
    return (
      <Tooltip title={value || ''} arrow>
        <Typography variant="body2" component="span">
          {formatDate(value || null, 'll')}
        </Typography>
      </Tooltip>
    );
  }
  if (type === CELL_DATA_TYPES.DATETIME) {
    return (
      <Tooltip title={value || ''} arrow>
        <Typography variant="body2" component="span">
          {formatDate(value || null, 'lll')}
        </Typography>
      </Tooltip>
    );
  }
  if (type === CELL_DATA_TYPES.TIMESTAMP) {
    return (
      <Tooltip title={value || ''} arrow>
        <Typography variant="body2" component="span">
          {formatTimestamp(value || null, 'lll')}
        </Typography>
      </Tooltip>
    );
  }
  if (type === CELL_DATA_TYPES.BOOL) {
    return (
      <Chip
        label={Number(value) === 1 ? 'Yes' : 'No'}
        color={Number(value) === 1 ? 'success' : 'error'}
        variant="filled"
        size="small"
      />
    );
  }
  if (type === CELL_DATA_TYPES.PLATFORM) {
    return <PlatformLabel value={value} />;
  }
  if (type === CELL_DATA_TYPES.SWITCH) {
    return (
      <Switch
        aria-label="status"
        color="primary"
        defaultChecked={!!value}
        onChange={(e) => fallbackValue(e)}
      />
    );
  }
  if (type === CELL_DATA_TYPES.STATUS) {
    return <StatusLabel value={value} />;
  }
  if (type === CELL_DATA_TYPES.CODE) {
    return (
      <Box component="span" sx={{ display: 'block' }}>
        {value || ''}
      </Box>
    );
  }

  if (type === CELL_DATA_TYPES.PREVIEW) {
    return (
      <IconButton
        aria-label="preview"
        disabled={cdnStatus !== 'Finished'}
        sx={{ padding: 0, color: APP_THEME }}
      >
        <Iconify icon="solar:play-bold" />
      </IconButton>
    );
  }

  if (type === CELL_DATA_TYPES.IMAGE) {
    if (value === null || value === '') {
      return (
        <Box
          sx={{
            height: 54,
            width: 96,
            bgcolor: '#808080',
          }}
        />
      );
    }

    return (
      <Box
        component="img"
        sx={{
          height: 54,
          width: 96,
          objectFit: 'contain',
        }}
        src={value}
      />
    );
  }

  return (
    <Typography variant="body2" component="span">
      {value || ''}
    </Typography>
  );
};

CellValue.propTypes = {
  type: PropTypes.string.isRequired,
  value: PropTypes.any,
  fallbackValue: PropTypes.func,
  cdnStatus: PropTypes.string,
};
CellValue.defaultProps = {
  value: null,
  fallbackValue: () => {},
  cdnStatus: null,
};

export default CellValue;
