import React from 'react';
import PropTypes from 'prop-types';
import { useRoutes, Navigate } from 'react-router-dom';
import ROUTE_PATH from '../config/routes.config';
import LoginView from '../views/auth/login.view';
import DashboardView from '../views/dashboard/dashboard.view';
import RealtimeDashboardView from '../views/dashboard/realtime-dashboard.view';
import ContentVideosView from '../views/content/videos.view';
import ContentTransmissionOrgansView from '../views/content/transmission-organs.view';
import ContentSequenceView from '../views/content/sequence.view';
import SubscribersView from '../views/subscribers/subscribers.view';
import SubscriptionsView from '../views/subscribers/subscriptions.view';
import AdminUsersView from '../views/admin-users/admin-users.view';
import ReportsOverviewView from '../views/reports/overview.view';
import ReportsLiveUsersView from '../views/reports/live-users.view';
import ReportsSessionsView from '../views/reports/sessions.view';
import ReportsSubscriptionsView from '../views/reports/subscriptions-summary.view';
import PageNotFoundView from '../views/errors/not-found.view';
import LogoutView from '../views/auth/logout.view';
import NotificationsView from '../views/settings/notification.view';
import SalutationView from '../views/settings/salutation.view';
import ContentCategoriesView from '../views/content/categories.view';
import ContentTestimonialTabsView from '../views/content/testimonial-tabs.view';
import ContentTestimonialCategoriesView from '../views/content/testimonial-categories.view';
import ContentTestimonialTextView from '../views/content/testimonial-text.view';
import {
  SHOW_ADMIN_USERS,
  SHOW_CONTENT_CATEGORIES,
  SHOW_CONTENT_SEQUENCE,
  SHOW_CONTENT_TRANSMISSION_ORGANS,
  SHOW_CONTENT_VIDEOS,
  SHOW_NOTIFICATIONS,
  SHOW_OVERVIEW_DASHBOARD,
  SHOW_REALTIME_DASHBOARD,
  SHOW_REPORTS_LIVE_USERS,
  SHOW_REPORTS_OVERVIEW_VIDEOS,
  SHOW_REPORTS_SUBSCRIPTIONS,
  SHOW_REPORTS_WATCH_SESSIONS,
  SHOW_SALUTATION,
  SHOW_SUBSCRIBERS,
  SHOW_SUBSCRIPTIONS,
  SHOW_TESTIMONIALS,
} from '../config/const.config';

const AppRouter = ({ isLoggedIn }) => {
  const routeMappings = [];

  if (isLoggedIn) {
    routeMappings.push({
      path: ROUTE_PATH.LOGIN,
      element: <Navigate to={ROUTE_PATH.DASHBOARD} />,
      exact: true,
    });

    if (SHOW_OVERVIEW_DASHBOARD) {
      routeMappings.push({
        path: ROUTE_PATH.DASHBOARD,
        element: <DashboardView />,
        exact: true,
      });
    }

    if (SHOW_REALTIME_DASHBOARD) {
      routeMappings.push({
        path: ROUTE_PATH.REALTIME_DASHBOARD,
        element: <RealtimeDashboardView />,
        exact: true,
      });
    }

    if (SHOW_SUBSCRIBERS) {
      routeMappings.push({
        path: ROUTE_PATH.SUBSCRIBERS,
        element: <SubscribersView />,
        exact: true,
      });
    }

    if (SHOW_SUBSCRIPTIONS) {
      routeMappings.push({
        path: ROUTE_PATH.SUBSCRIPTIONS,
        element: <SubscriptionsView />,
        exact: true,
      });
    }

    if (SHOW_ADMIN_USERS) {
      routeMappings.push({
        path: ROUTE_PATH.ADMIN_USERS,
        element: <AdminUsersView />,
        exact: true,
      });
    }

    if (SHOW_CONTENT_CATEGORIES) {
      routeMappings.push({
        path: ROUTE_PATH.CONTENT_CATEGORIES,
        element: <ContentCategoriesView />,
        exact: true,
      });
    }

    if (SHOW_CONTENT_VIDEOS) {
      routeMappings.push({
        path: ROUTE_PATH.CONTENT_VIDEOS,
        element: <ContentVideosView />,
        exact: true,
      });
    }

    if (SHOW_CONTENT_TRANSMISSION_ORGANS) {
      routeMappings.push({
        path: ROUTE_PATH.CONTENT_TRANSMISSION_ORGANS,
        element: <ContentTransmissionOrgansView />,
        exact: true,
      });
    }

    if (SHOW_CONTENT_SEQUENCE) {
      routeMappings.push({
        path: ROUTE_PATH.CONTENT_SEQUENCE_VIDEOS,
        element: <ContentSequenceView />,
        exact: true,
      });
    }

    if (SHOW_TESTIMONIALS) {
      routeMappings.push({
        path: ROUTE_PATH.CONTENT_TESTIMONIALS_TABS,
        element: <ContentTestimonialTabsView />,
        exact: true,
      });

      routeMappings.push({
        path: ROUTE_PATH.CONTENT_TESTIMONIALS_CATEGORIES,
        element: <ContentTestimonialCategoriesView />,
        exact: true,
      });

      routeMappings.push({
        path: ROUTE_PATH.CONTENT_TESTIMONIALS_TEXT,
        element: <ContentTestimonialTextView />,
        exact: true,
      });
    }

    if (SHOW_NOTIFICATIONS) {
      routeMappings.push({
        path: ROUTE_PATH.NOTIFICATIONS,
        element: <NotificationsView />,
        exact: true,
      });
    }

    if (SHOW_SALUTATION) {
      routeMappings.push({
        path: ROUTE_PATH.SALUTATION,
        element: <SalutationView />,
        exact: true,
      });
    }

    if (SHOW_REPORTS_OVERVIEW_VIDEOS) {
      routeMappings.push({
        path: ROUTE_PATH.REPORTS_OVERVIEW_VIDEOS,
        element: <ReportsOverviewView />,
        exact: true,
      });
    }

    if (SHOW_REPORTS_LIVE_USERS) {
      routeMappings.push({
        path: ROUTE_PATH.REPORTS_LIVE_USERS,
        element: <ReportsLiveUsersView />,
        exact: true,
      });
    }

    if (SHOW_REPORTS_WATCH_SESSIONS) {
      routeMappings.push({
        path: ROUTE_PATH.REPORTS_SESSIONS,
        element: <ReportsSessionsView />,
        exact: true,
      });
    }

    if (SHOW_REPORTS_SUBSCRIPTIONS) {
      routeMappings.push({
        path: ROUTE_PATH.REPORTS_SUBSCRIPTIONS,
        element: <ReportsSubscriptionsView />,
        exact: true,
      });
    }

    routeMappings.push({
      path: ROUTE_PATH.LOGOUT,
      element: <LogoutView />,
      exact: true,
    });
  }

  routeMappings.push({
    path: '*',
    element: isLoggedIn ? <PageNotFoundView /> : <LoginView />,
  });

  const routes = useRoutes(routeMappings);

  return routes;
};

AppRouter.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
};

export default AppRouter;
