// Define flags
export const MULTIPLE_CAT_PER_VIDEO = false;
export const SHOW_OVERVIEW_DASHBOARD = true;
export const SHOW_REALTIME_DASHBOARD = false;
export const SHOW_SUBSCRIBERS = true;
export const SHOW_SUBSCRIPTIONS = true;
export const SHOW_ADMIN_USERS = false;
export const SHOW_NOTIFICATIONS = true;
export const SHOW_SALUTATION = false;
export const SHOW_CONTENT_CATEGORIES = true;
export const SHOW_CONTENT_VIDEOS = true;
export const SHOW_CONTENT_TRANSMISSION_ORGANS = true;
export const SHOW_CONTENT_SEQUENCE = true;
export const SHOW_TESTIMONIALS = true;
export const SHOW_REPORTS_OVERVIEW_VIDEOS = true;
export const SHOW_REPORTS_LIVE_USERS = true;
export const SHOW_REPORTS_WATCH_SESSIONS = true;
export const SHOW_REPORTS_SUBSCRIPTIONS = true;
export const SHOW_REPORTS_LIVE_SESSIONS = true;

// Define commonly used constants
export const LS_ADMIN_USER_AUTH_TOKEN = '__ssft_admin_t';
export const LS_ADMIN_USER_NAME = '__ssft_admin_n';
export const LS_ADMIN_USER_EMAIL = '__ssft_admin_e';
export const LS_ADMIN_USER_ROLE = '__ssft_admin_r';
export const LS_ADMIN_USER_ID = '__ssft_admin_i';

export const TYPE_LABELS = {
  // 0: 'Text',
  1: 'Link',
  // 2: 'Audio',
};

export const TYPE_LABELS_KEYS = {
  // Text: 0,
  Link: 1,
  // Audio: 2,
};

export const STATUS_LABELS = {
  0: 'Inactive',
  1: 'Active',
  2: 'Deleted',
  5: 'Requested Delete',
  11: 'Pending Verification',
  12: 'Cancellation Applied',
};

export const VIDEO_TYPES = {
  0: 'Intro',
  1: 'Chapter',
  2: 'Transmission',
  3: 'Testimonial',
  4: 'Practice',
};

export const TRANSMISSION_ORGAN = {
  0: 'Heart',
  1: 'Lungs',
  2: 'Leg',
  3: 'Eyes',
};

export const STATUS_COLORS = {
  0: 'error',
  1: 'success',
  2: 'error',
  5: 'warning',
  11: 'warning',
  12: 'warning',
};

export const LANGUAGES = [
  {
    value: 'fr',
    label: 'French',
  },
  {
    value: 'de',
    label: 'German',
  },
  {
    value: 'nl',
    label: 'Dutch',
  },
  {
    value: 'es',
    label: 'Spanish',
  },
];

export const CONTENT_ALIAS = {
  CATEGORY_SINGULAR: 'Program',
  CATEGORY_PLURAL: 'Programs',
  VIDEO_SINGULAR: 'Video',
  VIDEO_PLURAL: 'Videos',
};

export const ACTIONS = {
  CREATE: {
    value: 'create',
    label: 'Create New',
  },
  EDIT: {
    value: 'update',
    label: 'Edit',
  },
  TRANSLATE: {
    value: 'translate',
    label: 'Translate',
  },
  VIEW: {
    value: 'view',
    label: 'View',
  },
  DELETE: {
    value: 'delete',
    label: 'Delete',
  },
  CHANGE_PASSWORD: {
    value: 'change_password',
    label: 'Change Password',
  },
  ASSIGN_CATEGORIES: {
    value: 'assign_books',
    label: `Assign ${CONTENT_ALIAS.CATEGORY_PLURAL}`,
  },
  UPLOAD_MP4: {
    value: 'upload_mp4',
    label: 'Upload MP4',
  },
  MANAGE_SUBSCRIPTIONS: {
    value: 'manage_subscriptions',
    label: 'Manage Subscriptions',
  },
  GRACE_SUBSCRIPTION: {
    value: 'grace_subscriptions',
    label: 'Grace Subscriptions',
  },
};

// Place it in CSS file
export const FOCUS_PALETTE_BGCOLOR = 'rgba(255, 255, 255, 0.25)';
